<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" has-modal-card>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Insira o usuário a ser inserido</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Username">
              <b-input
                type="text"
                v-model="username"
                placeholder="Username"
                required>
              </b-input>
            </b-field>

            <b-field label="Senha">
              <b-input
                type="password"
                v-model="password"
                placeholder="Senha"
                required>
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Fechar</button>
            <button class="button is-primary" @click="addUser">Gravar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'ModalInsertUser',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    addUser () {
      this.$emit('addUser', { username: this.username, password: this.password })
    },
    closeModal () {
      this.$emit('closeModal')
    },
    phoneUpdateHandler (phoneObj) {
      this.countryCode = phoneObj.countryCallingCode
      this.validPhone = phoneObj.nationalNumber
    }
  }
}
</script>

<style scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
  }

  .modal-card-body {
    overflow: visible !important;
  }
</style>
