<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Administradores
    </hero-bar>
    <section class="section is-main-section">
      <ModalInsertUser
        v-if="isComponentModalActive"
        v-on:addUser="handleAddUser"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive" />
      <b-loading v-if="fetchingUsers" :is-full-page="true" v-model="fetchingUsers" :can-cancel="false"></b-loading>
      <UsersList v-else :contacts='allUsers' v-on:removeContact="removeUser" />
      <FabButton v-if="false" v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Novo usuário" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import UsersList from '@/components/users/UsersList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalInsertUser from '@/components/modals/ModalInsertUser'

export default {
  name: 'Users',
  components: {
    HeroBar,
    TitleBar,
    UsersList,
    FabButton,
    ModalInsertUser
  },
  data () {
    return {
      isComponentModalActive: false
    }
  },
  computed: {
    ...mapGetters(['allUsers', 'fetchingUsers']),
    titleStack () {
      return ['Cadastros', 'Usuários Administradores']
    }
  },
  methods: {
    ...mapActions(['addUser', 'fetchUsers', 'deleteUser']),
    handleAddUser (user) {
      this.closeModal()
      this.addUser(user)
    },
    handleAddClick () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.isComponentModalActive = false
    },
    removeUser (user) {
      this.deleteUser(user.id)
      this.$buefy.toast.open('Administrador excluido com sucesso!')
    }
  },
  created () {
    this.fetchUsers()
  }
}
</script>
