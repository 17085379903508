<template>
  <div>
    <div v-if="allContacts.length > 0">
      <b-table
        :data="allContacts"
        :bordered="true"
        :striped="true"
        :narrowed="false"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="true">

        <b-table-column field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="username" label="Username" v-slot="props">
          {{ props.row.username }}
        </b-table-column>

        <b-table-column field="phone" width="200" label="Ações" v-slot="props">
          <b-button type="is-danger" @click="confirmContactDelete(props.row)">Excluir</b-button>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserList',
  props: {
    contacts: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  computed: {
    allContacts: {
      get () {
        return this.contacts
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    confirmContactDelete (contact) {
      this.$buefy.dialog.confirm({
        title: 'Excluir contato',
        message: 'Deseja <b>excluir</b> este usuário?',
        confirmText: 'Excluir contato',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeContact', contact)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
